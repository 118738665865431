import React from "react";
import NavBar from "../components/navbar";
import Keaton from "../../Images/keaton.jpeg";
import Michele from "../../Images/michele.png";

function ContactUs() {
  return (
    <div>
      <NavBar />
      <section className="section">
        <div
          id="w-node-_6ffa5c51-674f-2d5b-0f9c-104ebddf9932-dfb070ee"
          className="w-layout-layout quick-stack-5 wf-layout-layout"
        >
          <div
            id="w-node-_6ffa5c51-674f-2d5b-0f9c-104ebddf9933-dfb070ee"
            className="w-layout-cell"
          >
            <div className="div-block-24">
              <img
                src={Keaton}
                loading="lazy"
                alt=""
                className="image-6"
                style={{ objectFit: "cover" }}
              />
              <h1 className="heading-2">Keaton Benning</h1>
              <div>Director of Technology</div>
              <a href="tel:7722580748">772-828-0628</a>
              <a href="mailto:keaton@medspanetwork.com">
                Keaton@medspanetwork.com
              </a>
            </div>
          </div>
          <div
            id="w-node-_6ffa5c51-674f-2d5b-0f9c-104ebddf9934-dfb070ee"
            className="w-layout-cell"
          >
            <div className="div-block-24">
              <img
                src={Michele}
                loading="lazy"
                alt=""
                className="image-6"
                style={{ objectFit: "cover" }}
              />
              <h1 className="heading-2">Michele Woodhouse</h1>
              <div>Sales Director</div>
              <a href="tel:7722589650">772-258-9650</a>
              <a href="mailto:michele.woodhouse@medspanetwork.com">
                michele.woodhouse@medspanetwork.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;

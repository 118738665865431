import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LOGO from "../../Images/TRANSPARENT2.png";
import SunevaLogo from "../../Images/Suneva-Medical-wTagline.png";
import UseAuth from "../../Components/App/auth";
import { useUser } from "../../Context/userContext";

export default function Login() {
  const [username, setUserName] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState("");
  const [buttonAvail, setButtonAvail] = useState(true);
  const [warning, setWarning] = useState("");
  const navigate = useNavigate();
  const auth = UseAuth();
  const { setNewUserInformation } = useUser();

  useEffect(() => {
    if (username) {
      localStorage.setItem("username", username);
    }
  }, [username]);

  async function loginUser(e) {
    console.log("test");
    e.preventDefault();
    setButtonAvail(false);

    await axios
      .post("/api/v2/Suneva/accounts/suneva-login", {
        userName: username,
        password: password,
      })
      .then((res) => {
        if (res.data.status == "success") {
          localStorage.setItem("userType", JSON.stringify(res.data.data.type));
          localStorage.setItem("userId", JSON.stringify(res.data.data._id));
          localStorage.setItem("authToken", res.data.token);
          setNewUserInformation(res.data.data);

          auth.login();

          if (res.data.data.type === true) {
            navigate("/adminDashboard");
          } else {
            navigate("/userDashboard");
          }
        } else {
          setWarning(res.data.message);
        }
      });
  }

  return (
    <div className="">
      <div className="section-2">
        <div className="div-block-11">
          <div className="div-block-27">
            <img className="image-3" src={LOGO} alt="" loading="lazy" />
            <div className="div-block-28"></div>
            <img src={SunevaLogo} loading="lazy" alt="" className="image-8" />
          </div>
          <div className="form-block-2 w-form">
            <form
              method="get"
              name="email-form"
              data-name="Email Form"
              id="email-form"
              className="login-form"
              data-wf-page-id="65fdb61b247a875889235df3"
              data-wf-element-id="6951570d-9c44-601b-b073-5d153bccc8d0"
              onSubmit={(e) => loginUser(e)}
            >
              <label htmlFor="name">Username</label>
              <input
                required
                className="text-field-2 w-input"
                type="text"
                value={username}
                placeholder="Email"
                onChange={(e) => setUserName(e.target.value.toLowerCase())}
              />
              <label htmlFor="email">Password</label>
              <input
                required
                className="w-input"
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value.replace(/-/g, ""));
                }}
              />
              <h3>{warning}</h3>
              <input
                type="submit"
                data-wait="Please wait..."
                className="submit-button w-button"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
      {/* <div style={{ margin: "auto", textAlign: "center" }}>
        <img
          alt="Medspa Network Logo"
          src={LOGO}
          style={{
            maxWidth: "500px",
            width: "100%",
            margin: "auto",
            textAlign: "center",
          }}
        />
      </div>

      <form
        onSubmit={(e) => loginUser(e)}
        style={{ width: "100%", maxWidth: "900px", margin: "auto" }}
      >
        <label>
          <input
            className="loginOrderInputFields"
            type="text"
            value={username}
            placeholder="Email"
            onChange={(e) => setUserName(e.target.value.toLowerCase())}
          />
        </label>
        <label>
          <input
            style={{ marginTop: "45px" }}
            className="loginOrderInputFields"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value.replace(/-/g, ""));
            }}
          />
        </label>
        {warning !== "" ? (
          <>
            <div style={{ color: "red" }}>{warning}</div>
            <div>
              <a href="tel:7728280628">Or Call Tech Support</a>
            </div>
          </>
        ) : null}
        <div style={{ marginTop: "30px" }}>
          {buttonAvail ? (
            <button className="loginButton" type="submit">
              Login
            </button>
          ) : (
            <button
              className="loginButton"
              style={{ backgroundColor: "grey" }}
              type="submit"
            >
              Processing
            </button>
          )}
        </div>
      </form> */}
    </div>
  );
}

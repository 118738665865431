import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import DashboardNav from "../Dashboard/dashboardNav";
import { intToDollars } from "../Functions/intConversions";

export default function ViewTransaction() {
  const navigate = useNavigate();
  const [transactionDetails, setTransactionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "#e3e3e3",
    overflowY: "scroll",
  };

  const queryString = window.location.pathname.split("/")[2];

  useEffect(() => {
    axios({
      method: "get",
      url: `/api/orders/getTransaction/${queryString}`,
    }).then(function (res) {
      console.log(res.data);
      setLoading(false);
      setTransactionDetails(res.data);
      return;
    });
  }, [queryString]);

  return (
    <div style={{ maxHeight: "100vh", overflowY: "scroll" }}>
      <Header />
      <div
        style={{
          margin: "auto",
          marginTop: "0px",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />
        <div style={mainPanel}>
          <div
            style={{
              backgroundColor: "white",
              padding: "5px",
              margin: "0px",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={{
                  width: "100px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <p style={{ fontWeight: "800", paddingLeft: "10px" }}>
                /{transactionDetails.company}/ Purchase
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
              <h2 style={{ fontWeight: "400" }}>
                Tier {" " + transactionDetails.tier + " "} pricing
              </h2>
              <h2 style={{ fontWeight: "400" }}>
                {intToDollars(Number(transactionDetails.amount))}
              </h2>

              {transactionDetails.shippingStatus === "Shipped" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "green",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      content: "",
                    }}
                  />
                  <p
                    style={{
                      color: "green",
                      height: "100%",
                      padding: "5px 10px",
                      fontSize: "18px",
                      fontWeight: "800",
                      margin: "0px",
                    }}
                  >
                    {transactionDetails.shippingStatus}
                  </p>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      content: "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      height: "100%",
                      padding: "5px 10px",
                      fontSize: "18px",
                      fontWeight: "800",
                      margin: "0px",
                    }}
                  >
                    Transaction approved - Awaiting Shipping
                  </p>
                </div>
              )}
            </div>
          </div>
          <div style={{ overflowY: "scroll" }}>
            <div
              style={{
                padding: "0px 10px",
                margin: "auto",
                marginTop: "10px",
                maxWidth: "900px",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              {loading === false ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  ></div>
                  <div>
                    <h2 style={{ marginTop: "30px" }}>Contact Info: </h2>
                    <div>
                      <h4 style={{ fontWeight: "400" }}>
                        <strong></strong>
                        {transactionDetails.first_name +
                          " " +
                          transactionDetails.last_name}
                      </h4>
                    </div>

                    <div>
                      <a href={"tel:" + transactionDetails.shippingPhone}>
                        <h4 style={{ fontWeight: "400" }}>
                          Phone: {transactionDetails.shippingPhone}
                        </h4>
                      </a>
                    </div>
                    <div>
                      <a href={"mailto:" + transactionDetails.email}>
                        <h4 style={{ fontWeight: "400" }}>
                          Email: {transactionDetails.email}
                        </h4>
                      </a>
                    </div>
                  </div>
                  <div>
                    <h2 style={{ marginTop: "60px" }}>Shipping Address: </h2>

                    <div>
                      <h4 style={{ fontWeight: "400" }}>
                        {transactionDetails.company}
                      </h4>
                    </div>
                    <div>
                      <h4 style={{ fontWeight: "400" }}>
                        Attn: {transactionDetails.attn}
                      </h4>
                    </div>
                    <div>
                      <h4 style={{ fontWeight: "400" }}>
                        {transactionDetails.shippingAddress}
                      </h4>
                    </div>

                    <div>
                      <h4 style={{ fontWeight: "400" }}>
                        {transactionDetails.shippingCity +
                          " " +
                          transactionDetails.shippingState +
                          ", " +
                          transactionDetails.shippingZipcode}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h2 style={{ marginTop: "60px" }}>Billing Info</h2>
                      <div>
                        <h4 style={{ fontWeight: "400" }}>
                          {transactionDetails.billingAddress}
                        </h4>
                        <h4 style={{ fontWeight: "400" }}>
                          {transactionDetails.billingCity +
                            " " +
                            transactionDetails.billingState +
                            ", " +
                            transactionDetails.billingZipcode}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h2 style={{ marginTop: "60px" }}>Rep Info</h2>
                      <div>
                        <h4 style={{ fontWeight: "400" }}>
                          {transactionDetails.repName}
                        </h4>
                        <h4 style={{ fontWeight: "400" }}>
                          {transactionDetails.repEmail}
                        </h4>
                        <h4 style={{ fontWeight: "400" }}>
                          {transactionDetails.repPhone}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingBottom: "60px" }}>
                    <h2 style={{ marginTop: "60px" }}>Order</h2>
                    <div>
                      {Object.keys(transactionDetails).length !== 0
                        ? Object.keys(transactionDetails.cart).map(
                            (item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p>{item}</p>
                                  <p>
                                    {Number(
                                      transactionDetails.cart[item].ordered
                                    )}
                                  </p>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                </>
              ) : (
                <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

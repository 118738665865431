import axios from "axios";
import { useState } from "react";

export default function AddNewRep() {
  const [formData, setFormData] = useState({});

  function handleSubmission(e) {
    e.preventDefault();
    axios
      .post("/api/v2/Suneva/accounts/create-suneva-rep", formData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 300,
        gap: 30,
      }}
      onSubmit={(e) => {
        handleSubmission(e);
      }}
    >
      <input
        name="firstName"
        placeholder="First Name"
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }}
      />
      <input
        name="lastName"
        placeholder="Last Name"
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }}
      />
      <input
        name="email"
        placeholder="Email"
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }}
      />
      <input
        name="phoneNumber"
        placeholder="Phone Number"
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }}
      />
      <input type="submit" />
    </form>
  );
}

import React from "react";
import { useShoppingCart } from "../Context/shoppingCartContext";

export default function ProductRow({ props }) {
  const {
    increaseQuantity,
    setQuantity,
    decreaseQuantity,
    getItemQuantity,
    tier,
  } = useShoppingCart();

  const formattedPrice = Number(props.price).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="product-row">
      <div className="div-block-22">
        <div className="product-row-price">{formattedPrice}</div>
        <div className="product-name">{props.name}</div>
      </div>
      <div className="product-amount-adjustment-container">
        <button
          className="product-subtraction "
          onClick={() => {
            decreaseQuantity(props);
          }}
        >
          -
        </button>
        <div className="form-block-4 w-form" style={{ marginBottom: 0 }}>
          <form
            style={{ marginBottom: 0 }}
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p>{getItemQuantity(props.name)}</p>
            {/* <input
              style={{ marginBottom: 0 }}
              type="number"
              className="product-amount-entry w-input"
              maxLength="256"
              name="name"
              data-name="Name"
              placeholder={getItemQuantity(props.name)}
              id="name"
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = 0;
                }
                setQuantity(props, Number(e.target.value));
              }}
            /> */}
          </form>
        </div>
        <button
          className="product-subtraction "
          onClick={() => {
            increaseQuantity(props);
          }}
        >
          +
        </button>
      </div>
    </div>
  );
}

import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { ShoppingCart } from "../Components/shoppingCart";
import { UseLocaleStorage } from "../Hooks/useLocalStorage";
import AllProducts from "../json/products.json";

const ShoppingCartContext = createContext({});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }) {
  const [cartItems, setCartItems] = UseLocaleStorage("ShoppingCart", []);
  const [products, setProducts] = UseLocaleStorage("ProductsList", AllProducts);
  const [isOpen, setIsOpen] = useState(false);

  const cartQuantity = useMemo(() => {
    return cartItems.reduce((quantity, item) => item.quantity + quantity, 0);
  }, [cartItems]);

  const getItemQuantity = useCallback(
    (name) => {
      return cartItems.find((item) => item.name === name)?.quantity || 0;
    },
    [cartItems, setCartItems]
  );

  const increaseQuantity = useCallback(
    (props) => {
      setCartItems((currentItems) => {
        if (
          currentItems.find((item) => item.name === props.name)?.quantity ==
          null
        ) {
          return [...currentItems, { ...props, quantity: 1 }];
        } else {
          return currentItems.map((item) => {
            if (item.name === props.name) {
              if (props.name == "Expedited Shipping") {
                return { ...item, quantity: 1 };
              } else {
                return { ...item, quantity: item.quantity + 1 };
              }
            } else {
              return item;
            }
          });
        }
      });
    },
    [setCartItems]
  );

  function setQuantity(props, quantity) {
    setCartItems((currentItems) => {
      const item = currentItems.find((item) => item.name === props.name);
      if (item) {
        return currentItems.map((item) => {
          if (item.name === props.name) {
            return { ...item, quantity: quantity };
          } else {
            return item;
          }
        });
      } else {
        return [...currentItems, { ...props, quantity: quantity }];
      }
    });
  }

  function decreaseQuantity(props) {
    setCartItems((currentItems) => {
      if (
        currentItems.find((item) => item.name === props.name)?.quantity === 1
      ) {
        return currentItems.filter((item) => item.name !== props.name);
      } else {
        return currentItems.map((item) => {
          if (item.name === props.name) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  }

  function removeFromCart(name) {
    setCartItems((currentItems) => {
      const newItems = currentItems.filter((item) => item.name !== name);
      if (newItems.length == 0) {
        setIsOpen(false);
      }
      return newItems;
    });
  }

  function openCart() {
    setIsOpen(true);
  }

  function closeCart() {
    setIsOpen(false);
  }

  function setProductList(data) {
    setProducts(data);
  }

  function resetCart() {
    console.log("test");
    setCartItems([]);
    setIsOpen(false);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseQuantity,
        decreaseQuantity,
        setQuantity,
        removeFromCart,
        cartQuantity,
        openCart,
        closeCart,
        cartItems,
        setProductList,
        products,
        resetCart,
      }}
    >
      <ShoppingCart isOpen={isOpen} />
      {children}
    </ShoppingCartContext.Provider>
  );
}

export function getSemester(){
    let thisSemester = 0;
    let today = new Date();
    let todayMonth = today.getMonth() + 1;
    let todayYear = today.getFullYear();

    switch (todayMonth) {
        case 1:
        thisSemester = 1;
        break;

        case 2:
        thisSemester = 1;
        break;

        case 3:
        thisSemester = 1;
        break;

        case 4:
        thisSemester = 1;
        break;

        case 5:
        thisSemester = 1;
        break;

        case 6:
        thisSemester = 1;
        break;

        case 7:
        thisSemester = 2;
        break;

        case 8:
        thisSemester = 2;
        break;

        case 9:
        thisSemester = 2;
        break;

        case 10:
        thisSemester = 2;
        break;

        case 11:
        thisSemester = 2;
        break;

        case 12:
        thisSemester = 2;
        break;

        default:
        thisSemester = 0;
        break;
    }

    return("Semester_" + thisSemester + "_" + todayYear);
}
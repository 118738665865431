import React from "react";
import axios from "axios";

export default function TEST() {
  const sendEmails = async () => {
    axios.post("/api/accounts/remainingSpend");
    // axios.post("/api/admin/fixTotals");
  };

  const createSalts = () => {
    axios.post("/api/login/createSalts").then((res) => {
      console.log(res);
    });
  };

  const resetTotals = () => {
    axios.post("/api/admin/fixTotals").then((res) => {
      console.log(res);
    });
  };

  const queryString = window.location.pathname.split("/")[2];
  console.log(queryString);

  const link = document.createElement("a");
  link.href = `./semester_totals/${queryString}.xlsx`;
  link.setAttribute("download", `Account Tier Data - MedSpa Network`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  // link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);

  document.getElementById("link");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
        <button
          onClick={() => {
            sendEmails();
          }}
        >
          Send Rep Totals Email
        </button>
        <button
          color="primary"
          onClick={() => {
            createSalts();
          }}
        >
          Create salts and hash passwords
        </button>

        <button
          color="primary"
          onClick={() => {
            resetTotals();
          }}
        >
          Reset Totals
        </button>
        <img src={require("../../Images/downloading.png")} />
        <h1>Downloading Account Data</h1>
      </div>
    </div>
  );
}

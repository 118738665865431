import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/navbar";
import { useUser } from "../../Context/userContext";
import { useCompany } from "../../Context/companyContext";
import { useShoppingCart } from "../../Context/shoppingCartContext";
import Modal from "react-modal";
import CompanyDashboardRow from "../components/companyDashboardRow";

function Dashboard() {
  ////////////////////////////////////
  //  Setup Variables for further use
  ////////////////////////////////////
  const navigate = useNavigate();
  const { userInformation, setNewUserInformation, setNewAccounts } = useUser();
  const { setCompany } = useCompany();
  const { resetCart } = useShoppingCart();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([{}]);
  const [accountData, setAccountData] = useState([]);
  const [serverMessage, setServerMessage] = useState("");

  ////////////////////////////////////
  //  Get the user type and send a welcome message if its the
  //  first time on the app today
  ////////////////////////////////////
  useEffect(() => {
    resetCart();

    if (userInformation.accounts?.length > 0) {
      axios
        .post("/api/v2/Suneva/accounts/get-suneva-account-data/", {
          ids: userInformation.accounts,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "success") {
            setAccountData(res.data.data);
          } else {
            setServerMessage(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setServerMessage("There was an error trying to talk to the server");
        });
    }
  }, []);

  useMemo(() => {
    refreshAccounts();
  }, [userInformation.accounts]);

  async function refreshAccounts() {
    axios
      .post("/api/v2/Suneva/accounts/get-suneva-account-data/", {
        ids: userInformation.accounts,
      })
      .then((res) => {
        console.log(res.data);

        if (res.data.status == "success") {
          setAccountData(res.data.data);
        } else {
          setServerMessage(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setServerMessage("There was an error trying to talk to the server");
      });
  }

  async function deleteCompany() {
    await axios
      .post("/api/v2/Suneva/accounts/remove-suneva-account-from-rep/", {
        _id: userInformation._id,
        accountId: selectedAccount._id,
      })
      .then((res) => {
        if (res.data.status == "success") {
          setNewAccounts(res.data.data.accounts);
        }
      })
      .catch((err) => {
        alert(String(err));
      })
      .finally(() => {
        closeRemovalModal();
      });
  }

  async function searchForAnAccount(e) {
    e.preventDefault();
    await axios
      .post("/api/v2/Suneva/accounts/fuzzy-search-for-suneva-account/", {
        companyName: search,
      })
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {
        // alert(String(err));
      });
  }

  async function addAccount(account) {
    let doesExist = accountData.find(
      (selectedAccount) => selectedAccount._id == account._id
    );

    if (doesExist) {
      console.log("does exist");
      return;
    }

    await axios
      .post("/api/v2/Suneva/accounts/requestAcount/", {
        _id: userInformation._id,
        accountId: account._id,
      })
      .then((res) => {
        setNewAccounts([...userInformation.accounts, account._id]);
      })
      .catch((err) => {
        alert(String(err));
      })
      .finally(() => {
        closeSearchModal();
      });
  }

  ////////////////////////////////////
  //  MODALS
  ////////////////////////////////////

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontStyles: "normal",
      fontFamily: "inherit",
    },
  };
  Modal.setAppElement("#root");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  function openRemovalModal(account) {
    setIsOpen(true);
    setSelectedAccount(account);
  }

  function closeRemovalModal() {
    setIsOpen(false);
  }

  function openSearchModal() {
    setSearchIsOpen(true);
  }

  function closeSearchModal() {
    setSearchIsOpen(false);
    setSearch("");
    setSearchResults([]);
  }

  ////////////////////////////////////
  //  RENDER
  ////////////////////////////////////

  return (
    <div className="body-3" style={{ height: "100vh" }}>
      <NavBar />
      {/* <section>
        <div
          style={{
            width: "100%",
            backgroundColor: "goldenrod",
            padding: 10,
            minHeight: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
          }}
        >
          <h4>
            We are aware of an issue adding new accounts. To add a new account
            please test/call Keaton 772.828.0628
          </h4>
        </div>
      </section> */}
      <section className="section-3">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeRemovalModal}
          style={customStyles}
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Are you sure?</h2>
          <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
            <button
              onClick={() => {
                deleteCompany();
              }}
            >
              Yes, remove this account
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                borderColor: "red",
                borderStyle: "solid",
                borderWidth: 1,
                padding: 5,
                cursor: "pointer",
                color: "red",
              }}
              onClick={closeRemovalModal}
            >
              No, Cancel
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={searchIsOpen}
          onRequestClose={closeSearchModal}
          style={customStyles}
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Search for an Account
          </h2>
          <p>
            Here you can search for an account that has previously purchased
            from Medspa Network.
          </p>
          <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
            <form
              onSubmit={(e) => {
                searchForAnAccount(e);
              }}
            >
              <input
                placeholder="Start typing an account name"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <input type="submit" />
            </form>
            <div style={{ maxHeight: 300, overflowY: "scroll" }}>
              {searchResults.map((account, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <p>{account.company}</p>
                    <button
                      style={{
                        width: 150,
                        backgroundColor: "transparent",
                        color: "red",
                        borderColor: "red",
                        borderStyle: "solid",
                        borderWidth: 1,
                        padding: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        addAccount(account);
                      }}
                    >
                      Add
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        <div
          id="w-node-d3d585a4-a1cf-45b3-adbd-d1c1eea59e99-89235dea"
          className="w-layout-layout quick-stack-2 wf-layout-layout"
        >
          <div
            id="w-node-_197bf510-284c-43a8-b608-3eedd2f81183-7fd736be"
            className="w-layout-cell cell-4"
          >
            <div className="section-heading">
              <div className="div-block-25">
                <h1 className="view-company-large-heading">Your Accounts</h1>
                <button
                  style={{
                    backgroundColor: "transparent",
                    width: 30,
                    height: 30,
                    border: "none",
                    cursor: "pointer",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={() => {
                    refreshAccounts();
                  }}
                >
                  <img
                    src={require("../../Images/refresh.png")}
                    loading="lazy"
                    alt=""
                    className="image-7"
                  />
                </button>
              </div>
              <div className="div-block-19"></div>
            </div>
            {serverMessage ? (
              <h3 style={{ color: "red" }}>{serverMessage}</h3>
            ) : null}
            {accountData.length > 0
              ? accountData.map((account, index) => {
                  return (
                    <CompanyDashboardRow
                      key={index}
                      props={account}
                      openRemovalModal={openRemovalModal}
                    />
                  );
                })
              : null}
          </div>
          <div
            id="w-node-c81518b8-067d-3688-3c06-baf795c00880-7fd736be"
            className="w-layout-cell cell-3"
          >
            <div className="section-heading">
              <h1 className="view-company-large-heading">Quick Actions</h1>
              <div className="div-block-19"></div>
            </div>
            <div className="div-block-21">
              <button
                onClick={() => {
                  navigate("/newCompany");
                }}
                className="view-company-button"
              >
                Add New Account
              </button>
              <button
                onClick={() => {
                  openSearchModal();
                }}
                className="view-company-button"
              >
                Search for an Account
              </button>

              {/* <button href="#" className="view-company-button">
                Find Company
              </button>
              <button href="#" className="view-company-button">
                Remove Account
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
